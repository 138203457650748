import './Buy.css'
import CoverSection from './components/CoverSection'
import content from './content.yaml'

const Buy = () => {
  const { author, book } = content
  const { amazon, barnesAndNoble, ingramSpark } = book.links

  const buyLinks = [
    {
      title: 'Buy on Amazon',
      subtitle: 'Paperback & eBook',
      url: amazon,
    },
    {
      title: 'Buy on Barnes & Noble',
      subtitle: 'Hardcover and Paperback',
      url: barnesAndNoble,
    },
    {
      title: 'Buy on IngramSpark',
      subtitle: 'Hardcover and Paperback',
      url: ingramSpark,
      disabled: 'Coming Soon',
    },
  ]

  return (
    <div className="Buy">
      <div className="Buy-container">
        <CoverSection
          coverImage={book.cover_regular}
          coverImageHd={book.cover_hidpi}
          title={book.title}
          tagline={book.tagline}
          author={author.name}
        />

        <section className="BuySection-container">
          <div className="BuySection-links">
            {buyLinks.map((link) => {
              if (link.disabled) {
                return (
                  <div key={link.title} className="link link-disabled">
                    <span className="link-title">{link.title}</span>
                    <span className="link-subtitle">{link.subtitle}</span>
                    <span className="link-disabled-text">{link.disabled}</span>
                  </div>
                )
              } else {
                return (
                  <a
                    key={link.title}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    <span className="link-title">{link.title}</span>
                    <span className="link-subtitle">{link.subtitle}</span>
                  </a>
                )
              }
            })}
          </div>
        </section>

        <section className="Buy-home">
          <a className="Buy-home-link" href="/">
            Back to Home
          </a>
        </section>
      </div>
    </div>
  )
}

export default Buy
